import { createSelector } from 'reselect';
import isEmpty from 'lodash/fp/isEmpty';
import countBy from 'lodash/fp/countBy';
import getOr from 'lodash/fp/getOr';

import {
    ASSET_TYPE_TRUCK,
    ASSET_TYPE_BUS,
    ASSET_TYPE_VAN,
    ASSET_TYPE_TRAILER,
    ACTIVITY_WORKING,
    ACTIVITY_AVAILABLE,
    ACTIVITY_DRIVING,
    ACTIVITY_RESTING,
} from '../../data/dataDefinition';
import { getData } from '../app/selectors';
import {
    getActivityFilter,
    getAssetFilter,
    getNotificationFilter,
    getFilteredTransformedData,
} from '../table/tableSelectors';
import { featureToggles } from '../../configuration/setup/featureToggles';

export const hasActiveSummaryFilter = createSelector(
    getActivityFilter,
    getAssetFilter,
    getNotificationFilter,
    (activityFilter, assetFilter, notificationFilter) =>
        !isEmpty(activityFilter) || !isEmpty(assetFilter) || !isEmpty(notificationFilter)
);

export const hasTrucks = createSelector(
    getData,
    rawData => rawData && !isEmpty(rawData.filter(item => item.type === ASSET_TYPE_TRUCK))
);

export const hasBusses = createSelector(
    getData,
    rawData => rawData && !isEmpty(rawData.filter(item => item.type === ASSET_TYPE_BUS))
);

export const hasVans = createSelector(
    getData,
    rawData => rawData && !isEmpty(rawData.filter(item => item.type === ASSET_TYPE_VAN))
);

export const hasTrailers = createSelector(
    getData,
    rawData => rawData && !isEmpty(rawData.filter(item => item.type === ASSET_TYPE_TRAILER))
);

const getNotificationCounts = (data, counts) => {
    const exceptionsCountSource = featureToggles.platformNotifications ? 'notificationExceptions' : 'numberExceptions';
    const warningsCountSource = featureToggles.platformNotifications ? 'notificationWarnings' : 'numberWarnings';

    return data.reduce(
        (currentCounts, item) => ({
            // biome-ignore lint/performance/noAccumulatingSpread: TODO Find better alternative
            ...currentCounts,
            exceptions: currentCounts.exceptions + getOr(0, exceptionsCountSource)(item),
            warnings: currentCounts.warnings + getOr(0, warningsCountSource)(item),
        }),
        counts
    );
};

const getActivityCounts = data =>
    countBy(item => (!isEmpty(item.currentActivity) ? item.currentActivity.toLowerCase() : item.currentActivit), data);

const getAssetTypeCounts = data => countBy(item => item.type, data);

const getDriverCount = data => countBy(item => !isEmpty(item.driverId), data).true;

const counts = {
    trucks: 0,
    vans: 0,
    buses: 0,
    trailer: 0,
    drivers: 0,
    exceptions: 0,
    warnings: 0,
    [ACTIVITY_WORKING]: 0,
    [ACTIVITY_AVAILABLE]: 0,
    [ACTIVITY_DRIVING]: 0,
    [ACTIVITY_RESTING]: 0,
};

const getValueOrZero = value => value || 0;

const getCounts = data => {
    if (!data) {
        return counts;
    }

    const typeCounts = getAssetTypeCounts(data);
    const driverCount = getDriverCount(data);
    const activityCount = getActivityCounts(data);
    const notificationCounts = getNotificationCounts(data, counts);

    return {
        ...notificationCounts,
        trucks: getValueOrZero(typeCounts[ASSET_TYPE_TRUCK]),
        vans: getValueOrZero(typeCounts[ASSET_TYPE_VAN]),
        buses: getValueOrZero(typeCounts[ASSET_TYPE_BUS]),
        trailer: getValueOrZero(typeCounts[ASSET_TYPE_TRAILER]),
        drivers: getValueOrZero(driverCount),
        [ACTIVITY_WORKING]: getValueOrZero(activityCount[ACTIVITY_WORKING]),
        [ACTIVITY_AVAILABLE]: getValueOrZero(activityCount[ACTIVITY_AVAILABLE]),
        [ACTIVITY_DRIVING]: getValueOrZero(activityCount[ACTIVITY_DRIVING]),
        [ACTIVITY_RESTING]: getValueOrZero(activityCount[ACTIVITY_RESTING]),
    };
};

export const getTotalCounts = createSelector(getData, rawData => getCounts(rawData));

export const getSummaryCounts = createSelector(getFilteredTransformedData, data => getCounts(data));
