import getOr from 'lodash/fp/getOr';
import PropTypes from 'prop-types';
import Activity from '@rio-cloud/rio-uikit/Activity';

import { NoData } from '../asset/NoData';
import { NotificationBadge } from '../asset/NotificationBadge';
import { NotificationStates } from '../../../services/types';
import { featureToggles } from '../../../configuration/setup/featureToggles';

const ClusterAssetHeader = ({ asset }) => {
    const numberWarnings = featureToggles.platformNotifications ? asset.notificationWarnings : asset.numberWarnings;
    const numberExceptions = featureToggles.platformNotifications
        ? asset.notificationExceptions
        : asset.numberExceptions;

    return (
        <div className='display-flex padding-10 gap-5 align-items-center'>
            <div className='text-size-16 margin-top--1'>
                <span className={`rioglyph rioglyph-${asset.type}`} data-testid='asset-icon' />
            </div>
            <div className='text-size-16 text-medium flex-1-1'>
                <div className='line-height-125rel' data-testid='asset-name'>
                    {asset.name}
                </div>
            </div>
            {numberExceptions > 0 && (
                <div className='margin-left-4'>
                    <NotificationBadge level={NotificationStates.exception} amount={numberExceptions} />
                </div>
            )}
            {numberWarnings > 0 && (
                <div className='margin-left-4'>
                    <NotificationBadge level={NotificationStates.warning} amount={numberWarnings} />
                </div>
            )}
            <div className={'margin-left-4'}>
                {asset.activity && (
                    <Activity
                        bsSize={'sm'}
                        activity={asset.activity}
                        duration={asset.activityDuration}
                        isOutdated={asset.drivingTimeOutdated}
                    />
                )}
            </div>
        </div>
    );
};

const ClusterAssetProp = ({ asset, propName, icon = '' }) => (
    <div className='ClusterAssetProp display-flex padding-x-10 padding-top-5' data-testid={propName}>
        <div className='margin-left-2 margin-right-5'>
            <span className={`text-size-16 text-color-gray rioglyph rioglyph ${icon}`} />
        </div>
        <div className='flex-1-1 padding-top-2'>
            <div className='text-color-darker line-height-125rel'>{getOr(<NoData />, propName)(asset)}</div>
        </div>
    </div>
);

export const ClusterSidebarAsset = ({ asset, onClick }) => {
    const classes = `ClusterSidebarAsset border rounded margin-bottom-10 padding-bottom-10
        hover-bg-lightest cursor-pointer`;

    return (
        <div className={classes} data-testid={asset.vehicleId} onClick={onClick}>
            <ClusterAssetHeader asset={asset} />
            <hr className='margin-0' />
            <ClusterAssetProp asset={asset} propName='address' icon='rioglyph-map-marker' />
            <ClusterAssetProp asset={asset} propName='driver' icon='rioglyph-driver' />
        </div>
    );
};

ClusterSidebarAsset.propTypes = {
    asset: PropTypes.object.isRequired,
    onSelectAsset: PropTypes.func,
};
