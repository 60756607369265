import type { ApiPlatformNotification } from '../apiTypes';
import type { PlatformNotification } from '../types';
import compact from 'lodash/fp/compact';

const mapAssetPlatformNotification = (
    apiPlatformNotification: ApiPlatformNotification
): PlatformNotification | undefined => {
    if (apiPlatformNotification.importance === undefined || apiPlatformNotification.source_entity === undefined) {
        return;
    }
    return {
        importance: apiPlatformNotification.importance,
        assetId: apiPlatformNotification.source_entity.identifier,
    };
};

export const mapPlatformNotifications = (
    apiPlatformNotifications: ApiPlatformNotification[]
): PlatformNotification[] => {
    const assetNotifications = apiPlatformNotifications.filter(
        notification => notification.importance !== 'LOW' && notification.source_entity?.type === 'ASSET'
    );
    return compact(assetNotifications.map(mapAssetPlatformNotification));
};
