// import { featureToggles } from '../../../configuration/setup/featureToggles';

export const TABLE_COLUMN_NAME = 'name';
export const TABLE_COLUMN_VIN = 'vin';
export const TABLE_COLUMN_LICENSE_PLATE = 'licensePlate';
export const TABLE_COLUMN_DRIVER = 'driver';
export const TABLE_COLUMN_ADDRESS = 'address';
export const TABLE_COLUMN_LAT = 'lat';
export const TABLE_COLUMN_LNG = 'lng';
export const TABLE_COLUMN_MILEAGE = 'mileage';
export const TABLE_COLUMN_FUELLEVEL = 'fuelLevel';
export const TABLE_COLUMN_STATE_OF_CHARGE = 'stateOfCharge';
export const TABLE_COLUMN_ELECTRIC_RANGE = 'electricRange';
export const TABLE_COLUMN_SPEED = 'speed';
export const TABLE_COLUMN_TIMESTAMP = 'timestamp';
export const TABLE_COLUMN_TIMESTAMP_RELATIVE = 'timestampRelative';
export const TABLE_COLUMN_HINTS = 'hints';
export const TABLE_COLUMN_ACTIVITY = 'activity';
export const TABLE_COLUMN_ACTIVITY_TIMESTAMP = 'activityTimestamp';
export const TABLE_COLUMN_REMAINING_DRIVING_TIME = 'remainingCurrentDrivingTime';
export const TABLE_COLUMN_REMAINING_DRIVING_TIME_SORT = 'remainingCurrentDrivingTimeSort';

const defaultColumnOrder = [
    TABLE_COLUMN_NAME,
    TABLE_COLUMN_VIN,
    TABLE_COLUMN_LICENSE_PLATE,
    TABLE_COLUMN_DRIVER,

    TABLE_COLUMN_ADDRESS,
    TABLE_COLUMN_MILEAGE,
    TABLE_COLUMN_SPEED,
    TABLE_COLUMN_FUELLEVEL,
    TABLE_COLUMN_STATE_OF_CHARGE,
    TABLE_COLUMN_ELECTRIC_RANGE,

    TABLE_COLUMN_TIMESTAMP,
    TABLE_COLUMN_TIMESTAMP_RELATIVE,

    TABLE_COLUMN_LAT,
    TABLE_COLUMN_LNG,

    TABLE_COLUMN_HINTS,
    TABLE_COLUMN_ACTIVITY,
    TABLE_COLUMN_ACTIVITY_TIMESTAMP,
    TABLE_COLUMN_REMAINING_DRIVING_TIME,
];

const disabledColumns = [TABLE_COLUMN_NAME];

const defaultHiddenColumns = [
    TABLE_COLUMN_VIN,
    TABLE_COLUMN_LICENSE_PLATE,
    TABLE_COLUMN_FUELLEVEL,
    TABLE_COLUMN_SPEED,
    TABLE_COLUMN_STATE_OF_CHARGE,
    TABLE_COLUMN_ELECTRIC_RANGE,
    TABLE_COLUMN_LAT,
    TABLE_COLUMN_LNG,
    TABLE_COLUMN_TIMESTAMP_RELATIVE,
    TABLE_COLUMN_ACTIVITY_TIMESTAMP,
    TABLE_COLUMN_REMAINING_DRIVING_TIME,
];

const defaultSortKey = TABLE_COLUMN_NAME;

const columnLabels = {
    [TABLE_COLUMN_NAME]: 'intl-msg:livemonitor.label.name',
    [TABLE_COLUMN_VIN]: 'intl-msg:livemonitor.label.vin',
    [TABLE_COLUMN_LICENSE_PLATE]: 'intl-msg:glossary.licensePlate',
    [TABLE_COLUMN_DRIVER]: 'intl-msg:glossary.driver',
    [TABLE_COLUMN_ADDRESS]: 'intl-msg:livemonitor.label.address',
    [TABLE_COLUMN_LAT]: 'intl-msg:livemonitor.label.latitude',
    [TABLE_COLUMN_LNG]: 'intl-msg:livemonitor.label.longitude',
    [TABLE_COLUMN_MILEAGE]: 'intl-msg:livemonitor.label.mileage',
    [TABLE_COLUMN_FUELLEVEL]: 'intl-msg:livemonitor.label.fuelLevel',
    [TABLE_COLUMN_STATE_OF_CHARGE]: 'intl-msg:glossary.stateOfCharge',
    [TABLE_COLUMN_ELECTRIC_RANGE]: 'intl-msg:glossary.range',
    [TABLE_COLUMN_SPEED]: 'intl-msg:livemonitor.label.speed',
    [TABLE_COLUMN_TIMESTAMP]: 'intl-msg:livemonitor.label.lastPositionUpdate',
    [TABLE_COLUMN_TIMESTAMP_RELATIVE]: 'intl-msg:livemonitor.label.lastPositionUpdateRelative',
    [TABLE_COLUMN_HINTS]: 'intl-msg:livemonitor.label.notifications',
    [TABLE_COLUMN_ACTIVITY]: 'intl-msg:livemonitor.label.activity',
    [TABLE_COLUMN_ACTIVITY_TIMESTAMP]: 'intl-msg:livemonitor.label.activityTimestamp',
    [TABLE_COLUMN_REMAINING_DRIVING_TIME]: 'intl-msg:livemonitor.label.remainingCurrentDrivingTime',
};

const defaultColumnsDetails = {
    [TABLE_COLUMN_NAME]: {
        width: 250,
        defaultWidth: 250,
    },
    [TABLE_COLUMN_VIN]: {},
    [TABLE_COLUMN_LICENSE_PLATE]: {},
    [TABLE_COLUMN_DRIVER]: {},
    [TABLE_COLUMN_ADDRESS]: {},
    [TABLE_COLUMN_LAT]: {},
    [TABLE_COLUMN_LNG]: {},
    [TABLE_COLUMN_MILEAGE]: {},
    [TABLE_COLUMN_FUELLEVEL]: {},
    [TABLE_COLUMN_STATE_OF_CHARGE]: {},
    [TABLE_COLUMN_ELECTRIC_RANGE]: {},
    [TABLE_COLUMN_SPEED]: {},
    [TABLE_COLUMN_TIMESTAMP]: {},
    [TABLE_COLUMN_TIMESTAMP_RELATIVE]: {},
    [TABLE_COLUMN_HINTS]: {},
    [TABLE_COLUMN_ACTIVITY]: {
        width: 100,
        defaultWidth: 100,
    },
    [TABLE_COLUMN_ACTIVITY_TIMESTAMP]: {},
    [TABLE_COLUMN_REMAINING_DRIVING_TIME]: {},
};

const rawSortKeys = [
    'mileage',
    'speed',
    'fuelLevel',
    'stateOfCharge',
    'electricRange',
    'numberWarnings',
    'numberExceptions',
];

const sortKeyReplacement = {
    [TABLE_COLUMN_HINTS]: 'numberExceptions',
    [TABLE_COLUMN_TIMESTAMP]: 'eventTimestamp',
    [TABLE_COLUMN_TIMESTAMP_RELATIVE]: 'eventTimestamp',
    [TABLE_COLUMN_REMAINING_DRIVING_TIME]: TABLE_COLUMN_REMAINING_DRIVING_TIME_SORT,
    [TABLE_COLUMN_DRIVER]: 'lastName',
};

export const tableConfig = {
    defaultColumnOrder,
    defaultHiddenColumns,
    defaultColumnsDetails,
    disabledColumns,
    columnLabels,
    rawSortKeys,
    sortKeyReplacement,
    defaultSortKey,
    TABLE_CHUNK_SIZE: 50,
};
