import countBy from 'lodash/fp/countBy';
import omit from 'lodash/fp/omit';
import groupBy from 'lodash/fp/groupBy';
import keyBy from 'lodash/fp/keyBy';

import type {
    Asset,
    CombinedAssetData,
    Driver,
    DrivingTimes,
    LiveState,
    PlatformNotification,
    PlatformNotificationsCount,
} from '../types';

export type CombinedApiData = {
    liveStates: LiveState[];
    drivingTimes: DrivingTimes[];
    assets: Asset[];
    drivers: Driver[];
    platformNotifications: PlatformNotification[];
};

const removeGroupsFromDrivers = (drivers: Driver[]) =>
    drivers.map(driver => ({
        driverId: driver.driverId,
        driverName: driver.driverName,
        firstName: driver.firstName,
        lastName: driver.lastName,
    }));

const countPlatformNotifications = (platformNotifications: PlatformNotification[]): PlatformNotificationsCount => {
    const { HIGH: exceptions, MEDIUM: warnings } = countBy('importance', platformNotifications);
    return {
        notificationExceptions: exceptions || 0,
        notificationWarnings: warnings || 0,
    };
};

export const mapAssetsAndDriversWithStates = (apiData: CombinedApiData): CombinedAssetData[] => {
    const { assets, liveStates, drivers, drivingTimes, platformNotifications } = apiData;
    const assetStatesDictionary = keyBy('vehicleId', liveStates);
    const driversDictionary = keyBy('driverId', removeGroupsFromDrivers(drivers));
    const drivingTimesDictionary = keyBy('driverId', drivingTimes);
    const notificationsDictionary = groupBy('assetId', platformNotifications);

    return assets.map((asset: Asset) => {
        const vehicleId = asset.vehicleId;

        const liveState: LiveState | undefined = assetStatesDictionary[vehicleId];

        const driverData: Driver | undefined = liveState?.driverId ? driversDictionary[liveState.driverId] : undefined;

        const drivingTimesData: DrivingTimes | undefined =
            liveState?.driverId &&
            drivingTimesDictionary[liveState.driverId] &&
            drivingTimesDictionary[liveState.driverId].vehicleId === vehicleId
                ? drivingTimesDictionary[liveState.driverId]
                : undefined;

        const notificationsCount = countPlatformNotifications(notificationsDictionary[asset.vehicleId] || []);

        return {
            ...asset,
            ...liveState,
            ...omit('groupIds', driverData),
            ...drivingTimesData,
            ...notificationsCount,
        } as CombinedAssetData;
    });
};
