import parseISO from 'date-fns/parseISO';
import differenceInHours from 'date-fns/differenceInHours';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import values from 'lodash/fp/values';
import omitBy from 'lodash/fp/omitBy';
import isNil from 'lodash/fp/isNil';

import {
    type LiveState,
    type DrivingTimes,
    type DrivingTimesList,
    type NotificationCounts,
    type Notifications,
    type NotificationState,
    NotificationStates,
} from '../types';
import type { ApiLiveState, ApiDrivingTimes, ApiLiveStateList, ApiDrivingTimesList } from '../apiTypes';

const omitByIsNil = omitBy(isNil);

const getCurrentnessNotification = (positionTimeStamp: string): NotificationState => {
    if (positionTimeStamp) {
        const positionDate = parseISO(positionTimeStamp);
        if (differenceInHours(new Date(), positionDate) > 12) {
            return NotificationStates.exception;
        }
        if (differenceInHours(new Date(), positionDate) > 1) {
            return NotificationStates.warning;
        }
    }
    return NotificationStates.none;
};

const getMovementNotification = (lastMovementTimestamp: string): NotificationState => {
    if (lastMovementTimestamp) {
        const lastMovementDate = parseISO(lastMovementTimestamp);
        if (differenceInHours(new Date(), lastMovementDate) > 24) {
            return NotificationStates.exception;
        }
        if (differenceInHours(new Date(), lastMovementDate) > 12) {
            return NotificationStates.warning;
        }
    }
    return NotificationStates.none;
};

const calculateNotifications = (assetLiveStateDTO: ApiLiveState): Notifications => {
    const notifications = {
        currentness: getCurrentnessNotification(assetLiveStateDTO.position?.occurred_at),
        movement: getMovementNotification(assetLiveStateDTO.last_movement?.occurred_at),
    };
    return notifications;
};

const calculateNumberWarningsAndExceptions = (notifications?: Notifications): NotificationCounts => {
    if (notifications) {
        const notificationValues: NotificationState[] = values<NotificationState>(notifications);
        return {
            numberExceptions: notificationValues.filter(value => value === NotificationStates.exception).length,
            numberWarnings: notificationValues.filter(value => value === NotificationStates.warning).length,
        };
    }
    return {
        numberExceptions: 0,
        numberWarnings: 0,
    };
};

const calculateDrivingTimeOutdated = (sampleDateTime: string): boolean => {
    const parsedSampleDateTime = parseISO(sampleDateTime);
    const diff = differenceInMinutes(new Date(), parsedSampleDateTime);
    return diff > 5;
};

const mapAssetLiveStatesItem = (assetStatesItem: ApiLiveState): LiveState => {
    const notifications = calculateNotifications(assetStatesItem);
    return {
        vehicleId: assetStatesItem.asset_id,
        ...omitByIsNil({
            address: assetStatesItem.address?.value,
            addressTimestamp: assetStatesItem.address?.occurred_at,
            longitude: assetStatesItem.position?.value?.longitude,
            latitude: assetStatesItem.position?.value?.latitude,
            fuelLevel: assetStatesItem.fuel_level?.value,
            fuelLevelTimestamp: assetStatesItem.fuel_level?.occurred_at,
            stateOfCharge: assetStatesItem.state_of_charge?.value,
            stateOfChargeTimestamp: assetStatesItem.state_of_charge?.occurred_at,
            electricRange: assetStatesItem.electric_range?.value,
            electricRangeTimestamp: assetStatesItem.electric_range?.occurred_at,
            mileage: assetStatesItem.mileage?.value,
            mileageTimestamp: assetStatesItem.mileage?.occurred_at,
            speed: assetStatesItem.speed?.value && Math.round(assetStatesItem.speed?.value),
            speedTimestamp: assetStatesItem.speed?.occurred_at,
            bearing: assetStatesItem.heading?.value,
            bearingTimestamp: assetStatesItem.heading?.occurred_at,
            driverId: assetStatesItem.driver_id?.value,
            driverIdTimestamp: assetStatesItem.driver_id?.occurred_at,
            eventTimestamp: assetStatesItem.position?.occurred_at,
            notifications: notifications,
            ...calculateNumberWarningsAndExceptions(notifications),
        }),
    };
};

const mapDriverTimesItem = (driverState: ApiDrivingTimes): DrivingTimes => {
    return {
        driverId: driverState.driver_id,
        ...omitByIsNil({
            vehicleId: driverState.asset_id,
            currentActivity: driverState.activity_type,
            durationOfCurrentActivity: driverState.driving_and_resting_details?.current_duration_of_selected_activity,
            remainingCurrentDrivingTime: driverState.driving_and_resting_details?.remaining_current_driving_time,
            drivingTimeTimestamp: driverState.sample_date_time,
            drivingTimeOutdated: calculateDrivingTimeOutdated(driverState.sample_date_time),
        }),
    };
};

export const mapAssetLiveStates = (assetStatesDTO: ApiLiveStateList): LiveState[] => {
    return assetStatesDTO.items.map(mapAssetLiveStatesItem);
};

export const mapDriverTimes = (driverTimesDTO: ApiDrivingTimesList): DrivingTimesList => {
    const nextUrl = driverTimesDTO._links?.next && new URL(driverTimesDTO._links.next.href);
    const after = nextUrl?.searchParams.get('after') || undefined;
    return {
        items: driverTimesDTO.items.map(mapDriverTimesItem),
        after,
    };
};
