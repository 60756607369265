import type { IntlShape } from 'react-intl';
import type { Dispatch } from 'redux';
import some from 'lodash/fp/some';

import { actions } from './actions';
import { transformData } from '../data/dataDefinition';
import { config } from '../config';
import { mapAssetList } from './mapper/mapAssetList';
import { fetchData } from '../configuration/setup/fetch';
import { mapAssetLiveStates, mapDriverTimes } from './mapper/mapStates';
import { getAssets, getDrivers, getDrivingTimes } from '../features/app/selectors';
import { mapDriversList } from './mapper/mapDrivers';
import { featureToggles } from '../configuration/setup/featureToggles';
import { getAccessToken } from '../configuration/tokenHandling/tokenSlice';
import { getLocale } from '../configuration/lang/langSlice';
import { isUserSessionExpired } from '../configuration/login/loginSlice';
import type { RootState } from '../configuration/setup/store';
import {
    type Asset,
    type Driver,
    type DrivingTimes,
    type DrivingTimesList,
    type GeoBookingState,
    GeoBookingStates,
    type LiveState,
    type TransformedData,
} from './types';
import { fetchPlatformNotifications } from './fetchPlatformNotifications';
import { buildFetchResult, type CustomResponse, Status } from './buildFetchResult';
import { type CombinedApiData, mapAssetsAndDriversWithStates } from './mapper/mapAssetsAndDriversWithStates';

const fetchThen =
    // biome-ignore lint/suspicious/noExplicitAny: We don't know the type
        <T>(mapFunction: (response: any) => any) =>
        async (uri: string, token: string) => {
            try {
                const response = await fetchData(uri, token);
                const data = mapFunction(response);
                return buildFetchResult<T>(Status.FETCH_SUCCESS, data, null);
            } catch (error) {
                return buildFetchResult<T>(Status.FETCH_FAILURE, [] as T, error as Error);
            }
        };

const fetchAssetLiveState = (token: string, locale?: string) => {
    const assetLiveStateUri = `${config.backend.ASSET_LIVE_STATE_SERVICE}/assets?locale=${locale}`;
    return fetchThen<LiveState[]>(mapAssetLiveStates)(assetLiveStateUri, token);
};

const getOrFetchDrivingTimes = async (
    token: string,
    state: RootState,
    forceFetch = false,
    after?: string
): Promise<CustomResponse<DrivingTimes[]>> => {
    if (!forceFetch) {
        const drivingTimes = getDrivingTimes(state) || [];
        if (Array.isArray(drivingTimes) && drivingTimes.length !== 0) {
            return buildFetchResult(Status.FETCH_SUCCESS, drivingTimes, null);
        }
    }

    const drivingTimesUri = `${config.backend.DRIVING_TIMES_SERVICE}/drivers${after ? `?after=${after}` : ''}`;
    const drivingTimesResponse = await fetchThen<DrivingTimesList>(mapDriverTimes)(drivingTimesUri, token);

    if (drivingTimesResponse.data?.after) {
        const moreDrivingTimes = await getOrFetchDrivingTimes(token, state, true, drivingTimesResponse.data.after);
        const items = moreDrivingTimes.data || [];
        return buildFetchResult(Status.FETCH_SUCCESS, [...drivingTimesResponse.data.items, ...items], null);
    }
    return buildFetchResult(Status.FETCH_SUCCESS, drivingTimesResponse.data?.items || [], null);
};

const getOrFetchAssets = (token: string, state: RootState, forceFetch = false) => {
    if (!forceFetch) {
        const assets = getAssets(state) || [];
        if (Array.isArray(assets) && assets.length !== 0) {
            return buildFetchResult<Asset[]>(Status.FETCH_SUCCESS, assets, null);
        }
    }
    const assetListUri = `${config.backend.LIVEMONITOR_SERVICE}/states/assets`;
    return fetchThen<Asset[]>(mapAssetList)(assetListUri, token);
};

const getOrFetchDrivers = (token: string, state: RootState, forceFetch = false) => {
    if (!forceFetch) {
        const drivers = getDrivers(state) || [];
        if (Array.isArray(drivers) && drivers.length !== 0) {
            return buildFetchResult<Driver[]>(Status.FETCH_SUCCESS, drivers, null);
        }
    }

    const driverListUri = `${config.backend.LIVEMONITOR_SERVICE}/states/drivers`;
    return fetchThen<Driver[]>(mapDriversList)(driverListUri, token);
};

export const findHasAnyGeoBooked = some((asset: Asset) => asset.geoBookingState === GeoBookingStates.active);
export const findHasAnyGeoPending = some((asset: Asset) => asset.geoBookingState === GeoBookingStates.pending);
export const getOverallGeoBookingState = (transformedData: TransformedData[]): GeoBookingState => {
    if (findHasAnyGeoBooked(transformedData)) {
        return GeoBookingStates.active;
    }
    if (findHasAnyGeoPending(transformedData)) {
        return GeoBookingStates.pending;
    }
    return GeoBookingStates.inactive;
};

let callCounterAdministration = 0;
let callCounterDrivingTimes = 0;

type CustomResponseCombinedApiData = {
    [key in keyof CombinedApiData]: CustomResponse<CombinedApiData[key]>;
};

export const fetchAssetsAndDriversWithStates =
    (intl: IntlShape, forceFetch?: boolean) => async (dispatch: Dispatch, getState: () => RootState) => {
        const state = getState();
        const token = getAccessToken(state);
        const locale = getLocale(state);

        if (isUserSessionExpired(state)) {
            console.warn('Session expired');
            return;
        }

        const forceFetchAdministration = callCounterAdministration === 0 || !!forceFetch;
        const forceFetchDrivingTimes = callCounterDrivingTimes === 0;

        try {
            const assets = await getOrFetchAssets(token, state, forceFetchAdministration);
            if (assets.status !== Status.FETCH_SUCCESS) {
                throw assets.error;
            }

            const [liveStates, drivingTimes, drivers, platformNotifications] = await Promise.all([
                fetchAssetLiveState(token, locale),
                getOrFetchDrivingTimes(token, state, forceFetchDrivingTimes),
                getOrFetchDrivers(token, state, forceFetchAdministration),
                fetchPlatformNotifications(token),
            ]);

            const responses: CustomResponseCombinedApiData = {
                liveStates,
                drivers,
                drivingTimes,
                platformNotifications,
                assets,
            };

            const data = toApiData(responses);

            const mappedAssetsAndDriversWithStates = mapAssetsAndDriversWithStates(data);

            dispatch(actions.assetsChanged(data.assets));
            dispatch(actions.driversChanged(data.drivers));
            dispatch(actions.drivingTimesChanged(data.drivingTimes));

            dispatch(actions.rawDataChanged(mappedAssetsAndDriversWithStates));

            const transformedData = transformData(mappedAssetsAndDriversWithStates, intl);
            const overallGeoBookingState = getOverallGeoBookingState(transformedData);

            dispatch(actions.transformedDataChanged(transformedData));
            dispatch(actions.overallGeoBookingStateChanged(featureToggles.simulateGeoBooked || overallGeoBookingState));

            // TODO Decide how often to updates assets and drivers, now 15*20s /60s = every 5 minutes
            callCounterAdministration = (callCounterAdministration + 1) % 15;
            callCounterDrivingTimes = (callCounterDrivingTimes + 1) % 3;
        } catch (error: unknown) {
            if (!error) {
                return;
            }

            const err = error as Error;

            if (err.message.startsWith('401')) {
                // For now silently log unauthenticated errors to the console as the SessionExpiredDialog will
                // be shown instead. No need to render a dedicated error message.
                console.info('Could not fetch data. Please log in.');
            } else {
                dispatch(actions.fetchInitialDataFailed(err));
            }
        }
    };

const toApiData = (response: CustomResponseCombinedApiData): CombinedApiData => ({
    liveStates: response.liveStates.data || [],
    drivingTimes: response.drivingTimes.data || [],
    assets: response.assets.data || [],
    drivers: response.drivers.data || [],
    platformNotifications: response.platformNotifications.data || [],
});
