import type { ApiPlatformNotification, ApiPlatformNotificationsList } from './apiTypes';
import { fetchData } from '../configuration/setup/fetch';
import { config } from '../config';
import { mapPlatformNotifications } from './mapper/mapPlatformNotifications';
import type { PlatformNotification } from './types';
import { buildFetchResult, Status } from './buildFetchResult';

const MAX_ITERATION_PAGE_LIMIT = 100;

/**
 * Note: this is the default and max limit of platform-notifications-api
 * when specified, this limit will be set in the next link too.
 */
const PAGE_ITEMS_LIMIT = 1000;

const paginatedPlatformNotifications = async function* (initialUri: string, token: string) {
    let response: ApiPlatformNotificationsList = { _links: { next: { href: initialUri } }, items: [] };
    let iterations = 0;
    do {
        response = await fetchData(response._links.next.href, token);
        if (!isValid(response)) {
            return;
        }
        yield response.items;
    } while (response.items.length && ++iterations < MAX_ITERATION_PAGE_LIMIT);
};

export const fetchPlatformNotifications = async (token: string) => {
    const allNotifications: ApiPlatformNotification[] = [];
    const uri = `${config.backend.PLATFORM_NOTIFICATIONS_SERVICE}/notifications?limit=${PAGE_ITEMS_LIMIT}`;
    try {
        for await (const notifications of paginatedPlatformNotifications(uri, token)) {
            notifications && allNotifications.push(...notifications);
        }
        const platformNotifications = mapPlatformNotifications(allNotifications);
        return buildFetchResult(Status.FETCH_SUCCESS, platformNotifications, null);
    } catch (error) {
        return buildFetchResult(Status.FETCH_FAILURE, [] as PlatformNotification[], error as Error);
    }
};

const isValid = (apiResponse: Partial<ApiPlatformNotificationsList>): apiResponse is ApiPlatformNotificationsList => {
    return apiResponse.items !== undefined && apiResponse?._links?.next?.href !== undefined;
};
