export type Coordinates = {
    latitude: number;
    longitude: number;
};

export type CoordinatesShort = {
    lat: number;
    lng: number;
};

export type NotificationCounts = {
    numberExceptions: number;
    numberWarnings: number;
};

export type Address = {
    id: string;
    type: string;
    resultType: string;
    administrativeAreaType: string;
    title?: string;
    address: {
        label: string;
    };
    position: CoordinatesShort;
};

export const GeoBookingStates = {
    active: 'active',
    inactive: 'inactive',
    pending: 'pending',
} as const;

export type GeoBookingState = (typeof GeoBookingStates)[keyof typeof GeoBookingStates];

export const FuelTypes = {
    diesel: 'diesel',
    electric: 'electric',
    gas: 'gas',
    hydrogen: 'hydrogen',
} as const;

export type FuelType = (typeof FuelTypes)[keyof typeof FuelTypes];

export type Asset = {
    vehicleId: string;
    name: string;
    type: string;
    vin: string;
    groupIds: string[];
    minimalProductBooked: boolean;
    // SMIT-2654 Currently this only represents 'active' state for smart route planning
    // In the future, it could be extended to reflect the booking state (e.g. PENDING)
    smartRoutePlanningBooked: boolean;
    geoBookingState: GeoBookingState;
    fuelType?: FuelType;
    licensePlate?: string;
    brand?: string;
};

export type Group = {
    id: string;
    name: string;
    position?: string;
};

export type LiveState = {
    vehicleId: string;
    address?: string;
    addressTimestamp?: string;
    longitude?: number;
    latitude?: number;
    fuelLevel?: number;
    fuelLevelTimestamp?: string;
    stateOfCharge?: number;
    stateOfChargeTimestamp?: string;
    electricRange?: number;
    electricRangeTimestamp?: string;
    mileage?: number;
    mileageTimestamp?: string;
    speed?: number;
    speedTimestamp?: string;
    bearing?: number;
    bearingTimestamp?: string;
    driverId?: string;
    driverIdTimestamp?: string;
    eventTimestamp?: string;
    notifications?: string[];
    numberExceptions?: number;
    numberWarnings?: number;
};

export type PlatformNotification = {
    importance: string;
    assetId: string;
};

export const NotificationStates = {
    none: 'none',
    warning: 'warning',
    exception: 'exception',
} as const;

export type NotificationState = (typeof NotificationStates)[keyof typeof NotificationStates];

export type Notifications = {
    currentness: NotificationState;
    movement: NotificationState;
};

type WithNotifications = {
    notifications: Notifications;
};

export type PlatformNotificationsCount = {
    notificationWarnings: number;
    notificationExceptions: number;
};

export type CombinedAssetData = Asset &
    Partial<LiveState> &
    Partial<Driver> &
    Partial<DrivingTimes> &
    Partial<WithNotifications> &
    Partial<PlatformNotificationsCount>;

// Some props need to be omitted as their type changes from number to string due to translation or
// adding units during the transformation
export type TransformedData = Omit<
    CombinedAssetData,
    | 'lat'
    | 'lng'
    | 'latitude'
    | 'longitude'
    | 'speed'
    | 'mileage'
    | 'fuelLevel'
    | 'stateOfCharge'
    | 'electricRange'
    | 'activityDuration'
    | 'remainingCurrentDrivingTime'
> & {
    driver?: string;
    lat?: string;
    lng?: string;
    latitude?: number;
    longitude?: number;
    speed?: string;
    mileage?: string;
    fuelLevel?: string;
    stateOfCharge?: string;
    electricRange?: string;
    timestamp?: string;
    timestampRelative?: string;
    activity?: string;
    activityTimestamp?: string;
    remainingCurrentDrivingTime?: string;
    remainingCurrentDrivingTimeSort?: number;
    activityDuration?: string;
    isMoving: boolean;
    dataKey: string;
};

export type Driver = {
    driverId: string;
    driverName: string;
    firstName: string;
    lastName: string;
    groupIds?: string[];
};

export type DrivingTimes = {
    driverId: string;
    vehicleId?: string;
    currentActivity?: string;
    durationOfCurrentActivity?: number;
    remainingCurrentDrivingTime?: number;
    drivingTimeTimestamp?: string;
    drivingTimeOutdated?: boolean;
};

export type DrivingTimesList = {
    items: DrivingTimes[];
    after?: string;
};

export type Poi = Coordinates & {
    id: string;
    name: string;
    address: string;
};

export type Pois = {
    workshopPois: Poi[];
    customerPois: Poi[];
};

export type Point = Coordinates & {
    address: string | null;
};

export type Geofence = {
    id: string;
    type: string;
    name: string;
    active: boolean;
    points?: Point[];
    radius?: number;
    center?: Point;
};
