import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { actions as appActions } from '../../services/actions';
import isNil from 'lodash/fp/isNil';

// Note: these IDs are also used as tree category for the URL, hence keep it short
export const TREE_CATEGORY_SEARCH = 'search';
export const TREE_CATEGORY_FILTER = 'filter';
export const TREE_CATEGORY_ROUTE = 'route';
export const TREE_CATEGORY_SMART_ROUTE = 'smart_route';
export const TREE_CATEGORY_CHARGING_STATIONS = 'charging';
export const TREE_CATEGORY_SIMPLE_PAY = 'bookablePois';
export const TREE_CATEGORY_ASSETS = 'assets';
export const TREE_CATEGORY_DRIVER = 'driver';
export const TREE_CATEGORY_POIS = 'pois';
export const TREE_CATEGORY_GEOFENCES = 'geofences';
export const TREE_CATEGORY_CUSTOM_POIS = 'customPois';
export const TREE_CATEGORY_WORKSHOP_POIS = 'workshopPois';

export const TREE_POI_CATEGORY_CHARGING_STATIONS = 'charging';
export const TREE_POI_CATEGORY_BOOKABLE_POIS = 'bookablePois';
export const TREE_POI_CATEGORY_BOOKABLE_POIS_TEST = 'bookablePoisTest';
export const TREE_POI_CATEGORY_GEOFENCES = 'geofences';
export const TREE_POI_CATEGORY_CUSTOM_POIS = 'customPois';
export const TREE_POI_CATEGORY_WORKSHOP_POIS = 'workshopPois';

export const ASSET_DISPLAY_NAME_VEHICLE_NAME = 'vehicleName';
export const ASSET_DISPLAY_NAME_VIN = 'vin';
export const ASSET_DISPLAY_NAME_LICENSE_PLATE = 'licensePlate';

export const ASSET_DISPLAY_MAP = {
    vehicleName: ASSET_DISPLAY_NAME_VEHICLE_NAME,
    vin: ASSET_DISPLAY_NAME_VIN,
    licensePlate: ASSET_DISPLAY_NAME_LICENSE_PLATE,
};

export type ObjectValues<T> = T[keyof T];
export type AssetDisplayNameType = ObjectValues<typeof ASSET_DISPLAY_MAP>;

export type TREE_CATEGORY =
    | 'search'
    | 'filter'
    | 'route'
    | 'smart_route'
    | 'assets'
    | 'driver'
    | 'pois'
    | 'customPois'
    | 'workshopPois'
    | 'geofences'
    | 'charging'
    | 'bookablePois'
    | 'bookablePoisTest';

export type TREE_POI_CATEGORY =
    | 'customPois'
    | 'workshopPois'
    | 'geofences'
    | 'charging'
    | 'bookablePois'
    | 'bookablePoisTest';

export const TREE_ASSET_TYPES = ['truck', 'bus', 'van', 'trailer'];
export const TREE_FUEL_TYPES = ['fuel-diesel', 'fuel-electric', 'fuel-gas', 'fuel-hydrogen'];

export type TreeState = {
    selectedDriverIds: string[];
    selectedAssetGroupIds: string[];
    selectedAssetIds: string[];
    expandedAssetGroups: string[];
    expandedDriverGroups: string[];
    treeCategory: TREE_CATEGORY;
    treePoiCategory: TREE_POI_CATEGORY;
    isTreeOpen: boolean;
    globalSearchValue: string;
    showEmptyGroups: boolean;
    showAssetGroups: boolean;
    showDriverGroups: boolean;
    showFuelType?: boolean;
    assetDisplayNameType: AssetDisplayNameType;
};

export const defaultTreeState: TreeState = {
    selectedDriverIds: [],
    selectedAssetGroupIds: [],
    selectedAssetIds: [],
    expandedAssetGroups: [],
    expandedDriverGroups: [],
    treeCategory: TREE_CATEGORY_ASSETS,
    treePoiCategory: TREE_POI_CATEGORY_CUSTOM_POIS,
    isTreeOpen: true,
    globalSearchValue: '',
    showEmptyGroups: false,
    showAssetGroups: true,
    showDriverGroups: true,
    showFuelType: undefined,
    assetDisplayNameType: ASSET_DISPLAY_NAME_VEHICLE_NAME,
};

export const treeSlice = createSlice({
    name: 'tree',
    initialState: defaultTreeState,
    reducers: {
        emptyGroupsToggled: (state: TreeState) => ({ ...state, showEmptyGroups: !state.showEmptyGroups }),
        assetGroupsToggled: (state: TreeState) => ({ ...state, showAssetGroups: !state.showAssetGroups }),
        driverGroupsToggled: (state: TreeState) => ({ ...state, showDriverGroups: !state.showDriverGroups }),
        fuelTypeToggled: (state: TreeState) => ({
            ...state,
            showFuelType: !isNil(state.showFuelType) && !state.showFuelType,
        }),
        treePoiCategoryChanged: (state: TreeState, action: PayloadAction<TREE_POI_CATEGORY>) => ({
            ...state,
            treePoiCategory: action.payload,
        }),
        assetDisplayNameTypeSelected: (state: TreeState, action: PayloadAction<AssetDisplayNameType>) => ({
            ...state,
            assetDisplayNameType: action.payload,
        }),

        // handled by the mapSlice
        // searchResultSelected: (state: TreeState, action: PayloadAction<unknown>) => ({
        //     ...state,
        // }),
    },
    extraReducers: builder => {
        builder.addCase(appActions.expandedAssetGroupsChanged, (state: TreeState, action: PayloadAction<unknown>) => {
            state.expandedAssetGroups = action.payload as string[];
        });
        builder.addCase(appActions.expandedDriverGroupsChanged, (state: TreeState, action: PayloadAction<unknown>) => {
            state.expandedDriverGroups = action.payload as string[];
        });
        builder.addCase(appActions.selectedAssetsChanged, (state: TreeState, action: PayloadAction<unknown>) => {
            state.selectedAssetIds = (action.payload as string[]) || defaultTreeState.selectedAssetIds;
        });
        builder.addCase(appActions.selectedAssetGroupsChanged, (state: TreeState, action: PayloadAction<unknown>) => {
            state.selectedAssetGroupIds = (action.payload as string[]) || defaultTreeState.selectedAssetGroupIds;
        });
        builder.addCase(appActions.selectedDriversChanged, (state: TreeState, action: PayloadAction<unknown>) => {
            state.selectedDriverIds = action.payload as string[];
        });
        builder.addCase(appActions.treeToggled, (state: TreeState) => {
            state.isTreeOpen = !state.isTreeOpen;
        });
        builder.addCase(appActions.globalSearchValueChanged, (state: TreeState, action: PayloadAction<string>) => {
            state.globalSearchValue = action.payload;
        });
        builder.addCase(
            appActions.treePoiCategoryChanged,
            (state: TreeState, action: PayloadAction<TREE_POI_CATEGORY>) => {
                state.treePoiCategory = action.payload;
            }
        );
        builder.addCase(appActions.treeCategoryChanged, (state: TreeState, action: PayloadAction<TREE_CATEGORY>) => {
            if (state.treeCategory !== action.payload) {
                state.treeCategory = action.payload;

                const isSingleAssetSelected = state.selectedAssetIds.length === 1;
                const hasNoGroupsSelected = state.selectedAssetGroupIds.length === 0;

                // Reset active asset selection on tree category change to not limit the search to a single asset only
                if (isSingleAssetSelected && hasNoGroupsSelected) {
                    // state.selectedDriverIds = defaultTreeState.selectedDriverIds;
                    // state.selectedAssetGroupIds = defaultTreeState.selectedAssetGroupIds;
                    state.selectedAssetIds = defaultTreeState.selectedAssetIds;
                }
            }
        });
    },
});

export const {
    /*searchResultSelected,*/ emptyGroupsToggled,
    assetGroupsToggled,
    driverGroupsToggled,
    fuelTypeToggled,
    treePoiCategoryChanged,
    assetDisplayNameTypeSelected,
} = treeSlice.actions;
export { appActions };

export default treeSlice.reducer;
