import { detectFeatureToggles, asString, asBoolean } from '@rio-cloud/rio-uikit/urlFeatureToggles';

import { useUserAccount } from '../login/loginSlice';

// Define old toggles to clean up
const oldToggles = ['ft_eventTeaser', 'ft_survey', 'ft_menu', 'ft_groupedPois', 'ft_featureVoting'];

// Define toggle transformation functions with their respective names and
// export the detected feature toggles as a singleton
export const featureToggles = detectFeatureToggles(
    {
        enforcedEnv: asString('ft_env'),
        enforcedLocale: asString('ft_enforceLocale'),
        enforcedLocalWidget: asString('ft_localWidget'),
        tracing: asBoolean('ft_tracing'),
        support: asBoolean('ft_support'),
        demo: asBoolean('ft_demo'),
        simulateGeoBooked: asString('ft_simulateGeoBooked'),
        smartRoutePlanning: asBoolean('ft_smartRoutePlanning'),
        smartRoutePlanningBooking: asBoolean('ft_srpBooking'),
        simplePay: asBoolean('ft_simplePay'),
        simplePayTest: asBoolean('ft_simplePayTest'),
        devMode: asBoolean('ft_devMode'),
        skipChristmas: asBoolean('skipChristmas'),
        platformNotifications: asBoolean('ft_platformNotifications'),
    },
    oldToggles
);

export const useWhiteListedFeatureToggles = (whiteList: string[] = []) => {
    const accountId = useUserAccount();
    return whiteList.includes(accountId);
};

export const useSmartRouteFeatureToggle = () => {
    const smartRouteWhiteList = [
        'mockaccount',
        '8ef04c71-8105-4bfb-a97c-a70db9d66975', // "RIO VIT"
        '237c58e3-60b5-4442-a9ec-8641ea39325b', // "Lastkraftwagenbauer"
        '284b3c1f-72c1-4669-a51b-c7ca9cc3c967', // “Demofleet MAN Sales”
        'ac464fce-f700-49a7-aa13-60b0c5c3f9ce', // “Testaccount MAN Truck & Bus SE”
        '0eaa962f-ddf9-4280-9d37-3d0760959493', // "EEV-Trucktesting"
        'be0fa59d-6b42-4025-9039-1c1ac8efb4e1', // MAN Truck and Bus SE
        '0ff74f0d-c891-47ca-a7e6-9501be388fe7', // LoadFox
    ];
    return useWhiteListedFeatureToggles(smartRouteWhiteList);
};

// Additional feature toggles just for charging stations
export const useChargingStationsFeatureToggle = () => {
    const whiteList = [
        // 'mockaccount',
        'be0fa59d-6b42-4025-9039-1c1ac8efb4e1', // MAN Truck & Bus SE - requested by "Phillip Walter"
        '7283d2d7-88f3-46ee-828e-01ee81d7f2b7', // MAN Truck & Bus Iberia - requested by "Phillip Walter"
        '15a7d09e-6b01-42e3-ad11-08df2c6a4bad', // MAN Truck & Bus ITALIA SPA - requested by "Phillip Walter"
        '3d8eac7c-d6f4-422e-90bc-a6fa7778e9f0', // MAN Truck & Bus Commerce - requested by "Phillip Walter"
        '482df194-8e57-4e45-ba4d-691ad2d21908', // MAN Truck & Bus BE - requested by "Phillip Walter"
        '3a3f27f1-c499-477b-8a21-bb0a09d6556b', // MAN Truck & Bus Deutschland GmbH - requested by "Phillip Walter"
        '0ff74f0d-c891-47ca-a7e6-9501be388fe7', // LoadFox Transport Solutions GmbH - requested by "Phillip Walter"
        '9809ca36-ca31-4bd3-b53a-be403f9d03c9', // Traton Charging Solutions - requested by "Phillip Walter"
        'b7522019-a092-43da-8387-ce8825685ca2', // Traton Charging Solutions Test AB - requested by "Phillip Walter"
        '284b3c1f-72c1-4669-a51b-c7ca9cc3c967', // Demofleet MAN Sales - requested by "Phillip Walter"
        '1beca9f3-f75f-4fa2-95c5-a3615e41acfd', // Wellpappe Alzenau GmbH & Co. KG - requested by "Phillip Walter"
        'ca2a4f5b-77ac-481c-b4b1-948454040ca1', // Nanno Janssen GmbH - requested by "Phillip Walter"
        '0e56bf54-0b15-47ba-a2cc-c8f63cd9f124', // Vorführflotte DACH - requested by "Maximilian Nicolussi-Moretto" and Phillip Walter',
    ];
    return useWhiteListedFeatureToggles(whiteList);
};
