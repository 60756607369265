import isEmpty from 'lodash/fp/isEmpty';

import { UNGROUPED_ITEMS_GROUP_ID } from '../fetchGroups';
import type { ApiAssetList } from '../apiTypes';
import type { Asset } from '../types';

export const mapAssetList = (assetList: ApiAssetList): Asset[] =>
    assetList.items.map(asset => {
        const groups = asset.group_ids;
        return {
            vehicleId: asset.id,
            name: asset.name || '',
            displayName: asset.name,
            type: asset.type || '',
            vin: (asset.identification_type === 'vin' && asset.identification) || '',
            groupIds: groups && !isEmpty(groups) ? groups : [UNGROUPED_ITEMS_GROUP_ID],
            minimalProductBooked: asset.minimal_product_booked,
            smartRoutePlanningBooked: asset.smart_route_planning_booked,
            geoBookingState: asset.geo_booking_state,
            fuelType: asset.fuel_type,
            licensePlate: asset.license_plate,
            brand: asset.brand,
        };
    });
