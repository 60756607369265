import type { TreeGroup } from '@rio-cloud/rio-uikit/Tree';
import { useAppSelector } from '../../configuration/setup/hooks';
import {
    getExpandedAssetGroups,
    getExpandedDriverGroups,
    getFilteredAssetsByName,
    getFilteredCustomerPois,
    getFilteredDriversByName,
    getFilteredGeofences,
    getFilteredWorkshopPois,
    getGlobalSearchValue,
    assetDisplayNameType,
    getTreeAssets,
    getTreeCategory,
    getTreeDrivers,
    getTreeGroups,
    getTreePoiCategory,
    isTreeOpen,
    showAssetGroups,
    showDriverGroups,
    showEmptyGroups,
    showFuelType,
} from './treeSelectors';

export const useShowFuelType = () => useAppSelector(showFuelType);

export const useTreeAssets = () => useAppSelector(getTreeAssets);
export const useTreeDrivers = () => useAppSelector(getTreeDrivers);
export const useTreeGroups = () => useAppSelector(getTreeGroups) as TreeGroup[];

export const useTreeCategory = () => useAppSelector(getTreeCategory);
export const useTreePoiCategory = () => useAppSelector(getTreePoiCategory);
export const useIsTreeOpen = () => useAppSelector(isTreeOpen);

export const useGlobalSearchValue = () => useAppSelector(getGlobalSearchValue);

export const useExpandedAssetGroups = () => useAppSelector(getExpandedAssetGroups);
export const useExpandedDriverGroups = () => useAppSelector(getExpandedDriverGroups);

export const useFilteredAssets = () => useAppSelector(getFilteredAssetsByName);
export const useFilteredDrivers = () => useAppSelector(getFilteredDriversByName);
export const useFilteredCustomerPois = () => useAppSelector(getFilteredCustomerPois);
export const useFilteredWorkshopPois = () => useAppSelector(getFilteredWorkshopPois);
export const useFilteredGeofences = () => useAppSelector(getFilteredGeofences);

export const useShowEmptyGroups = () => useAppSelector(showEmptyGroups);
export const useShowAssetGroups = () => useAppSelector(showAssetGroups);
export const useShowDriverGroups = () => useAppSelector(showDriverGroups);

export const useAssetDisplayNameType = () => useAppSelector(assetDisplayNameType);
