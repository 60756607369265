import { useEffect, useRef } from 'react';

// Reload Livemonitor after 12 hours without interaction
const TIMEOUT_IN_MS = 43_200_000;

const interactionEvents = ['keydown', 'mousedown', 'scroll', 'touchend'];

export const useTimedPageReload = (timeout = TIMEOUT_IN_MS) => {
    // Reference to store the timeout ID
    const timerRef = useRef<number | null>(null);

    const handleTimeout = () => {
        if (timerRef.current !== null) {
            // Clear the existing timeout
            clearTimeout(timerRef.current);
        }

        timerRef.current = window.setTimeout(() => {
            window.location.reload();
        }, timeout);
    };

    useEffect(() => {
        for (const event of interactionEvents) {
            window.addEventListener(event, handleTimeout);
        }

        // Start the initial timeout
        handleTimeout();

        // Cleanup on unmount
        return () => {
            if (timerRef.current !== null) {
                // Clear the timeout
                clearTimeout(timerRef.current);
            }
            for (const event of interactionEvents) {
                window.removeEventListener(event, handleTimeout);
            }
        };
    }, [timeout]);
};
