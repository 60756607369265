export const Status = {
    FETCH_SUCCESS: 'FETCH_SUCCESS',
    FETCH_FAILURE: 'FETCH_FAILURE',
} as const;

export type Status = (typeof Status)[keyof typeof Status];

export type CustomResponse<T> = {
    status: Status;
    data?: T;
    error?: Error;
};

export const buildFetchResult = async <T>(status: Status, data: T | null, error: Error | null = null) => {
    return Promise.resolve({
        status,
        data,
        error,
    } as CustomResponse<T>);
};
