import memoize from 'lodash/fp/memoize';
// biome-ignore lint/suspicious/noShadowRestrictedNames: We do it anyways
import isFinite from 'lodash/fp/isFinite';
import get from 'lodash/fp/get';
import invert from 'lodash/fp/invert';
import parseISO from 'date-fns/parseISO';

import {
    TABLE_COLUMN_ACTIVITY,
    TABLE_COLUMN_ACTIVITY_TIMESTAMP,
    TABLE_COLUMN_DRIVER,
    TABLE_COLUMN_FUELLEVEL,
    TABLE_COLUMN_LAT,
    TABLE_COLUMN_LNG,
    TABLE_COLUMN_MILEAGE,
    TABLE_COLUMN_STATE_OF_CHARGE,
    TABLE_COLUMN_ELECTRIC_RANGE,
    TABLE_COLUMN_REMAINING_DRIVING_TIME,
    TABLE_COLUMN_REMAINING_DRIVING_TIME_SORT,
    TABLE_COLUMN_SPEED,
    TABLE_COLUMN_TIMESTAMP,
    TABLE_COLUMN_TIMESTAMP_RELATIVE,
} from '../features/table/tableConfig';
import type { IntlShape } from 'react-intl';
import type { CombinedAssetData, CoordinatesShort, TransformedData } from '../services/types';

export const DEMO_FLEET_ACCOUNT_ID = '47bbd84b-b46d-4bdc-a9d7-eb36b70358ec';

export const DEFAULT_ZOOM = 6;
export const DEFAULT_CENTER: CoordinatesShort = { lat: 49.84936369480352, lng: 9.126572076690017 };

export const ASSET_TYPE_TRUCK = 'truck';
export const ASSET_TYPE_BUS = 'bus';
export const ASSET_TYPE_VAN = 'van';
export const ASSET_TYPE_TRAILER = 'trailer';
export const ASSET_TYPE_DRIVER = 'driver';

export const ACTIVITY_WORKING = 'working';
export const ACTIVITY_AVAILABLE = 'available';
export const ACTIVITY_DRIVING = 'driving';
export const ACTIVITY_RESTING = 'resting';

export const activityIcons: Record<string, string> = {
    [ACTIVITY_WORKING]: 'rioglyph-status-working',
    [ACTIVITY_AVAILABLE]: 'rioglyph-status-available',
    [ACTIVITY_DRIVING]: 'rioglyph-status-driving',
    [ACTIVITY_RESTING]: 'rioglyph-status-resting',
};

export const availableActivities = Object.keys(activityIcons);

export const assetTypeIcons: Record<string, string> = {
    [ASSET_TYPE_TRUCK]: 'rioglyph-truck',
    [ASSET_TYPE_BUS]: 'rioglyph-bus',
    [ASSET_TYPE_VAN]: 'rioglyph-van',
    [ASSET_TYPE_TRAILER]: 'rioglyph-trailer',
};

export const mapTypeSet: Record<string, string> = {
    0: 'DEFAULT',
    1: 'FLEET_STYLE',
    2: 'TERRAIN',
    3: 'SATELLITE',
};

export const getMapTypeById = (id: number): string => mapTypeSet[id];
export const getMapTypeId = (type: string, set: Record<string, string>) =>
    Object.keys(set).find(key => set[key] === type);

export const MAP_LAYER_TRAFFIC = 'TRAFFIC';
export const MAP_LAYER_INCIDENTS = 'INCIDENTS';
export const MAP_LAYER_ROAD_RESTRICTIONS = 'ROAD_RESTRICTIONS';

const MAP_LAYERS_IDS: Record<string, number> = {
    [MAP_LAYER_TRAFFIC]: 1,
    [MAP_LAYER_INCIDENTS]: 2,
    [MAP_LAYER_ROAD_RESTRICTIONS]: 3,
};

export const getMapLayersIds = (layers: string[]) => layers.map(layer => MAP_LAYERS_IDS[layer]);
export const getMapLayersByIds = (layerIds: string[] | null) => {
    const invertedMapLayers = invert(MAP_LAYERS_IDS);
    return layerIds?.map((layerId: string) => invertedMapLayers[Number(layerId)]);
};

const formatFuelLevel = (fuelLevel: number | undefined) =>
    fuelLevel && isFinite(fuelLevel) ? `${fuelLevel.toFixed(0)} %` : undefined;

const formatStateOfCharge = (stateOfCharge: number | undefined) =>
    stateOfCharge && isFinite(stateOfCharge) ? `${stateOfCharge.toFixed(0)} %` : undefined;

const formatElectricRange = (electricRange: number | undefined) =>
    electricRange && isFinite(electricRange) ? `${electricRange.toFixed(0)} km` : undefined;

const formatSpeed = (speed: number | undefined) => (speed && isFinite(speed) ? `${speed} km/h` : undefined);

const formatCoord = (coord: number | undefined) => (coord && isFinite(coord) ? `${coord.toFixed(5)}` : undefined);

const formatDistance = (distance: number | undefined, intl: IntlShape) => {
    if (distance === 0) {
        return '0 km';
    }
    if (distance) {
        return `${intl.formatNumber(Number.parseInt(distance.toFixed(0)))} km`;
    }
};

const formatTimestamp = (timestamp: string | undefined, intl: IntlShape) => {
    if (timestamp) {
        const parsedDate = parseISO(timestamp);
        const date = intl.formatDate(parsedDate);
        const time = intl.formatTime(parsedDate);
        return `${time} ${date}`;
    }
};

const formatTimestampRelative = (timestamp: string | undefined, intl: IntlShape) => {
    if (timestamp) {
        const { value, unit } = getOptimalTimeUnit(parseISO(timestamp));
        return intl.formatRelativeTime(value, unit);
    }
};

const formatToHours = (durationInMinutes: number | undefined): string | undefined => {
    if ((durationInMinutes === 0 || durationInMinutes) && isFinite(durationInMinutes)) {
        const hours = Math.floor(durationInMinutes / 60);
        const minutes = `${durationInMinutes % 60}`.padStart(2, '0');
        return `${hours}:${minutes} h`;
    }
    return undefined;
};

const formatRemainingDrivingTime = (item: CombinedAssetData) => {
    const currentRdt = get('remainingCurrentDrivingTime', item);
    return {
        [TABLE_COLUMN_REMAINING_DRIVING_TIME]: formatToHours(currentRdt),
        [TABLE_COLUMN_REMAINING_DRIVING_TIME_SORT]: currentRdt,
    };
};

export const getOptimalTimeUnit = (
    fromDate: Date,
    toDate = new Date()
): { value: number; unit: Intl.RelativeTimeFormatUnit } => {
    const diffInSeconds = Math.round((fromDate.getTime() - toDate.getTime()) / 1000);
    const absDiff = Math.abs(diffInSeconds);

    if (absDiff < 60) {
        return { value: diffInSeconds, unit: 'second' };
    }
    if (absDiff < 3600) {
        return { value: Math.round(diffInSeconds / 60), unit: 'minute' };
    }
    if (absDiff < 86400) {
        return { value: Math.round(diffInSeconds / 3600), unit: 'hour' };
    }
    // return { value: Math.round(diffInSeconds / 86400), unit: 'day' };
    if (absDiff < 2592000) {
        return { value: Math.round(diffInSeconds / 86400), unit: 'day' };
    }
    if (absDiff < 31536000) {
        return { value: Math.round(diffInSeconds / 2592000), unit: 'month' };
    }
    return { value: Math.round(diffInSeconds / 31536000), unit: 'year' };
};

// Transform data in order to:
// - Translate item props as we search and sort accordingly with respect to translations or composed values
// - format values as they might be relevant for search
export const transformData = memoize((items: CombinedAssetData[], intl: IntlShape): TransformedData[] => {
    return items.map(item => {
        return {
            ...item,
            [TABLE_COLUMN_DRIVER]: item.driverName,
            [TABLE_COLUMN_MILEAGE]: formatDistance(item.mileage, intl),
            [TABLE_COLUMN_FUELLEVEL]: formatFuelLevel(item.fuelLevel),
            [TABLE_COLUMN_STATE_OF_CHARGE]: formatStateOfCharge(item.stateOfCharge),
            [TABLE_COLUMN_ELECTRIC_RANGE]: formatElectricRange(item.electricRange),
            [TABLE_COLUMN_SPEED]: formatSpeed(item.speed),
            [TABLE_COLUMN_LAT]: formatCoord(item.latitude),
            [TABLE_COLUMN_LNG]: formatCoord(item.longitude),
            [TABLE_COLUMN_TIMESTAMP]: formatTimestamp(item.eventTimestamp, intl),
            [TABLE_COLUMN_TIMESTAMP_RELATIVE]: formatTimestampRelative(item.eventTimestamp, intl),
            [TABLE_COLUMN_ACTIVITY]: item.currentActivity?.toLowerCase(),
            [TABLE_COLUMN_ACTIVITY_TIMESTAMP]: formatTimestamp(item.drivingTimeTimestamp, intl),
            ...formatRemainingDrivingTime(item),
            // not used as dedicated column
            addressTimestamp: formatTimestamp(item.addressTimestamp, intl),
            fuelLevelTimestamp: formatTimestamp(item.fuelLevelTimestamp, intl),
            stateOfChargeTimestamp: formatTimestamp(item.stateOfChargeTimestamp, intl),
            electricRangeTimestamp: formatTimestamp(item.electricRangeTimestamp, intl),
            mileageTimestamp: formatTimestamp(item.mileageTimestamp, intl),
            speedTimestamp: formatTimestamp(item.speedTimestamp, intl),
            bearingTimestamp: formatTimestamp(item.bearingTimestamp, intl),
            driverIdTimestamp: formatTimestamp(item.driverIdTimestamp, intl),
            activityDuration: formatToHours(item.durationOfCurrentActivity),
            isMoving: item?.speed ? item.speed > 0 : false,
            dataKey: item.vehicleId,
        };
    });
});
